import { useFeatureContinueLearningQuery } from '@fis/data-graphql';
import { FisLoadingIndicator } from '@fis/ui-loading-indicator';

import { ProgressPropTypes } from '../types';
import { ProgressCard } from './ui-progress-card';
import { UiProspectiveCourses } from '../../prospective-courses/ui-prospective-courses';
import { UiHeroTitle } from '@fis/ui-hero-title';
import { formatCourseLinks } from '../../../util-format-course-links';

export const FeatureProspectiveProgress = (props: ProgressPropTypes) => {
  const { data, error } = useFeatureContinueLearningQuery({
    fetchPolicy: 'cache-and-network',
  });

  const loading = !error && !data;

  const canvasEdges = data?.student?.courses?.edges || [];
  const formattedLinks = formatCourseLinks(canvasEdges);
  const startedPrework = props.startedPrework;

  if (loading) {
    return (
      <>
        <FisLoadingIndicator />
        <UiProspectiveCourses />
      </>
    );
  }

  if (formattedLinks.length === 0) {
    return (
      <>
        <UiHeroTitle titleText="Try Prep Work today!" />
        <UiProspectiveCourses />
      </>
    );
  }

  if (formattedLinks.length > 0 && !startedPrework) {
    return (
      <>
        <UiHeroTitle titleText="Start your prep work" />
        <ProgressCard {...props} links={formattedLinks} />
      </>
    );
  }

  return <ProgressCard {...props} links={formattedLinks} />;
};
