import React, { useEffect } from 'react';
import { useCurrentUser } from '@fis/data-current-user';
import { useGetProgressQuery, Student } from '@fis/data-graphql';

import Layout from '@fis/ui-layout';
import Main from '@fis/ui-main';

import { FeatureRecommendedResourceHomepageCta } from '../components/recommended-resources/recommended-resource-homepage-cta/feature-recommended-resource-homepage-cta';
import FeatureHeader from '../components/feature-header';
import FeatureProgressCard from '../components/progress/progress-card';
import CallToActionList from '../components/feature-call-to-action-list';
import { SocialLinks } from '../components/progress/progress-card/components/ui-social-links';

import { FeatureHomepageResourcesAndEvents } from '../components/feature-homepage-resources-and-events';
import { AdmissionInterviewNotification } from '../components/admission-interview';
import { FisLoadingIndicator } from '@fis/ui-loading-indicator';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import ToastErrorMessage from '../components/chat/toast-error';

/* -------------------------------------------------------------------------------------------------
 * Types
 * -----------------------------------------------------------------------------------------------*/
declare global {
  interface Window {
    dataLayer: any[];
  }
}

/* -------------------------------------------------------------------------------------------------
 * Page
 * -----------------------------------------------------------------------------------------------*/

export const Page = () => {
  const { data, error } = useGetProgressQuery({
    fetchPolicy: 'cache-first',
  });

  const router = useRouter();
  const code = router.query.error_code as string;
  const loading = !error && !data;
  const [currentUser] = useCurrentUser();
  useEffect(() => {
    if (code) {
      toast.error(<ToastErrorMessage code={code} />, {
        autoClose: false,
        closeOnClick: false,
      });
    }
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'login',
        userUUID: currentUser.uuid,
      });
    }
  }, [code]);

  if (loading) {
    return <FisLoadingIndicator />;
  }

  const student = data?.student;
  const cohort = student?.cohort;

  return (
    <Layout pageTitle="Home">
      <FeatureHeader />
      <AdmissionInterviewNotification />

      <Main>
        <FeatureProgressCard student={student as Student} />
        <FeatureRecommendedResourceHomepageCta />
        <FeatureHomepageResourcesAndEvents />
        {student.programStatus === 'prospective' && <SocialLinks />}
      </Main>
    </Layout>
  );
};

export default Page;
