import PropTypes from 'prop-types';
import {
  ContentTypeEnum,
  useFeatureDiscoverContentsQuery,
} from '@fis/data-graphql';
import { ResourceCard } from '../../resource-library/resource-card';
import {
  CardGrid,
  CardGridLoading,
  CardGridSection,
  CardGridSectionHeader,
} from '../../ui-card-grid';

export const RelatedLectureResources = () => {
  const { data, refetch, loading } = useFeatureDiscoverContentsQuery({
    variables: {
      limit: 3,
      filters: {
        type: ContentTypeEnum.Community,
      },
    },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return (
      <CardGridSection>
        <CardGridLoading />
      </CardGridSection>
    );
  } else if (!data?.contents || data?.contents?.length < 1) {
    return null;
  }

  const handleRefetch = () => {
    refetch({
      limit: 3,
      filters: {
        type: ContentTypeEnum.Community,
      },
    });
  };

  if (data?.contents?.length < 1) return null;

  return (
    <CardGridSection>
      <CardGridSectionHeader
        title={'Resources for You'}
        link={'/resource-library?recommended=true'}
      />
      <CardGrid>
        {data?.contents
          ? data?.contents.map((content) => {
              return (
                <ResourceCard
                  homepageVariant
                  key={content.uuid}
                  content={content}
                  handleRefetch={handleRefetch}
                />
              );
            })
          : null}
      </CardGrid>
    </CardGridSection>
  );
};

RelatedLectureResources.propTypes = {
  content: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    bookmark: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    imageUrl: PropTypes.string,
    disciplines: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    url: PropTypes.string,
  }),
  handleRefetch: PropTypes.func,
};

export default RelatedLectureResources;
