import { toast } from 'react-toastify';

import {
  ContentFragmentFragment,
  useToggleContentBookmarkedMutation,
  useMarkContentViewedMutation,
} from '@fis/data-graphql';
import { formatAcronyms } from '@fis/ui-format';

import { StyledResourceCard } from './ui-resource-card';

type ContentPropTypes = {
  content: ContentFragmentFragment;
  testId?: string;
  homepageVariant?: boolean;
  handleRefetch?: () => void;
};

export const ResourceCard = ({
  content,
  handleRefetch,
  homepageVariant,
  testId,
}: ContentPropTypes) => {
  const [toggleBookmarkMutation] = useToggleContentBookmarkedMutation({
    onError: () => toast('❗️ Something went wrong. Please try again.'),
    variables: {
      input: {
        contentUuid: content.uuid,
      },
    },
  });

  const [markContentViewedMutation] = useMarkContentViewedMutation({
    variables: {
      input: {
        contentUuid: content.uuid,
      },
    },
  });

  const markContentViewedIfEmbedUrl = () =>
    content.embedUrl ? markContentViewedMutation() : null;

  // For Homepage Highlights
  const handleHomepageBookmark = (e) => {
    e.preventDefault();
    toggleBookmarkMutation();
    handleRefetch();
  };
  // For Content Library domain
  const handleBookmark = (e) => {
    e.preventDefault();
    toggleBookmarkMutation();
  };

  const getDisciplines = () => {
    let disciplines;
    if (content.disciplines.length !== 0) {
      disciplines = formatAcronyms(
        content.disciplines.map(({ name }) => name)
      ).join(', ');
    } else {
      return null;
    }
    return disciplines;
  };

  return (
    <StyledResourceCard
      testId={testId}
      contentTitle={content.title}
      contentUrl={content.url}
      viewed={!!content.view}
      contentFormat={content.format}
      contentSlug={content.slug}
      programDisciplines={getDisciplines()}
      imageUrl={content.imageUrl}
      isBookmarked={!!content.bookmark}
      bookmarkBtnClick={(e) => {
        homepageVariant ? handleHomepageBookmark(e) : handleBookmark(e);
      }}
      handleEmbedUrlClick={() => markContentViewedIfEmbedUrl()}
      handleContentUrlClick={() => markContentViewedMutation()}
    />
  );
};

export default ResourceCard;
