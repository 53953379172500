import { useFeatureDiscoverContentsQuery } from '@fis/data-graphql';
import { ResourceCard } from '../../resource-library/resource-card';
import RelatedLectureResources from '../related-lectures/related-lectures-resources';
import {
  CardGridLoading,
  CardGridSection,
  CardGridSectionHeader,
} from '../../ui-card-grid';
import { UiCardRow } from '../ui-card-row';
import { RecommendedSectionContainer } from '../ui-bookmark-section-container';

export const BookmarkedResources = () => {
  const { data, refetch, loading } = useFeatureDiscoverContentsQuery({
    variables: {
      limit: 3,
      filters: {
        bookmarked: true,
      },
    },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return (
      <CardGridSection>
        <CardGridLoading />
      </CardGridSection>
    );
  } else if (data?.contents.length === 0) {
    return <RelatedLectureResources />;
  }

  const handleRefetch = () => {
    refetch({
      limit: 3,
      filters: {
        bookmarked: true,
      },
    });
  };

  return (
    <RecommendedSectionContainer>
      <CardGridSectionHeader
        title={'Bookmarked Resources'}
        link={'/resource-library?bookmarked=true'}
      />
      <UiCardRow>
        {data?.contents
          ? data?.contents.map((content) => {
              return (
                <ResourceCard
                  homepageVariant
                  key={content.uuid}
                  content={content}
                  handleRefetch={handleRefetch}
                />
              );
            })
          : null}
      </UiCardRow>
    </RecommendedSectionContainer>
  );
};
