import React from 'react';

import { Heading } from '@fis/ui-heading';
import { styled } from '@fis/util-theme';
import { BookmarkButton } from '@fis/ui-buttons';

import { EventImage } from '../ui-event-image';
import { ResponsiveFlexContainer } from '@fis/ui-responsive-flex-container';
import { UiEventCardBody } from './ui-event-card-body';

const EventCardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  background: '$gray800',
  borderRadius: '$3',
  padding: '$5',
  width: '100%',
  cursor: 'pointer',
  position: 'relative',
  transition: '$easeInOut',
  fontSize: '$20',
  color: '$gray300',
  minWidth: '0',
  gap: '$3',

  '.lightMode &': {
    background: '$gray100',
    color: '$gray600',
  },

  '&:hover': {
    cursor: 'pointer',
    boxShadow: '$blueGlow',

    '.lightMode &': {
      background: '$gray50',
      boxShadow: '$1',
    },
  },

  '&:focus': {
    outline: 'none',
    boxShadow: '$focusRing',
  },

  variants: {
    bookmarked: {
      true: {
        borderColor: '$yellow800',

        '&:hover': {
          borderColor: '$yellow600',
          boxShadow: '0, 0, 12px rgba(255, 233, 143, .1)',
        },
      },
    },
    verticalOrientation: {
      true: {
        padding: '$3',
        flexDirection: 'column',
        gap: '$7',
        maxWidth: '20.25rem',
        background: 'transparent',

        '&:hover': {
          background: '$tintDark8',
        },

        '.lightMode &': {
          background: 'transparent',

          '&:hover': {
            background: '$tintLight8',
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

const EventTime = styled('p', {
  fontSize: '$20',
  color: '$gray300',
  minWidth: '4rem',

  '.lightMode &': {
    color: '$gray600',
  },
});

const StyledBookmarkButton = styled(BookmarkButton, {
  alignSelf: 'start',
});

type EventCardProps = {
  verticalOrientation?: boolean;
  dataTestId: string;
  eventTitle?: string;
  eventDate?: string | boolean;
  eventTime: string;
  eventSpeaker: string;
  programDisciplines?: string;
  programPhases?: { name: string }[];
  eventUrl: string;
  remoteMeetingLink?: string;
  inPersonLocation?: string;
  imageUrl: string;
  eventType?: string[];
  bookmarkBtnClick;
  isBookmarked: boolean;
};

export const EventCard = (props: EventCardProps) => {
  const {
    verticalOrientation,
    dataTestId,
    eventTitle,
    eventDate,
    eventTime,
    eventSpeaker,
    programDisciplines,
    programPhases,
    eventUrl,
    remoteMeetingLink,
    inPersonLocation,
    imageUrl,
    eventType,
    bookmarkBtnClick, // requires e.preventDefault() to prevent clicks from reaching the parent card container
    isBookmarked,
  } = props;

  return verticalOrientation ? (
    <EventCardContainer
      verticalOrientation={true}
      data-testid={dataTestId}
      bookmarked={isBookmarked}
      as="a"
      href={eventUrl}
      target="_blank"
    >
      <EventImage imageSize="lg" imageUrl={imageUrl} eventType={eventType} />
      <ResponsiveFlexContainer flexGap={4}>
        <ResponsiveFlexContainer flexGap={4} flexColumn>
          <Heading styling="med">{eventTitle}</Heading>
          <EventTime data-testid={isBookmarked ? 'Bookmarked' : ''}>
            {`${eventDate} • ${eventTime}`}
          </EventTime>
        </ResponsiveFlexContainer>
        <StyledBookmarkButton
          onClick={bookmarkBtnClick}
          isBookmarked={isBookmarked}
        />
      </ResponsiveFlexContainer>
      <UiEventCardBody
        verticalOrientation={verticalOrientation}
        remoteMeetingLink={remoteMeetingLink}
        inPersonLocation={inPersonLocation}
        programDisciplines={programDisciplines}
        programPhases={programPhases}
        eventSpeaker={eventSpeaker}
        eventType={eventType}
      />
    </EventCardContainer>
  ) : (
    <EventCardContainer
      verticalOrientation={false}
      data-testid={dataTestId}
      bookmarked={isBookmarked}
      as="a"
      href={eventUrl}
      target="_blank"
    >
      <ResponsiveFlexContainer align="center" flexGap={16}>
        <ResponsiveFlexContainer align="center" justify="space-between">
          <Heading styling="lg">{eventTitle}</Heading>
          <EventTime data-testid={isBookmarked ? 'Bookmarked' : ''}>
            {eventTime}
          </EventTime>
        </ResponsiveFlexContainer>
        <StyledBookmarkButton
          onClick={bookmarkBtnClick}
          isBookmarked={isBookmarked}
        />
      </ResponsiveFlexContainer>
      <ResponsiveFlexContainer>
        <EventImage imageUrl={imageUrl} eventType={eventType} />
        <UiEventCardBody
          verticalOrientation={verticalOrientation}
          remoteMeetingLink={remoteMeetingLink}
          inPersonLocation={inPersonLocation}
          programDisciplines={programDisciplines}
          programPhases={programPhases}
          eventSpeaker={eventSpeaker}
          eventType={eventType}
        />
      </ResponsiveFlexContainer>
    </EventCardContainer>
  );
};

export default EventCard;
