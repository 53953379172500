import { styled } from '@fis/util-theme';

const ProgressBarContainer = styled('div', {
  background: '$tintDark8',
  height: '.75rem',
  width: '100%',
  borderRadius: '$2',

  '.lightMode &': {
    background: '$gray200',
  },
});

const ProgressBarInner = styled('div', {
  background: '$teal500',
  height: '100%',
  borderRadius: '$2',
  width: '0%',

  '.lightMode &': {
    background: '$teal400',
  },
});

export const UiProgressBar = (props: { value: number; max: number }) => {
  const { value, max } = props;

  return (
    <ProgressBarContainer>
      <ProgressBarInner
        style={{ width: `${Math.round((value / max) * 100)}%` }}
      />
    </ProgressBarContainer>
  );
};
