import {
  useGetSavedLiveEventsQuery,
  useToggleSaveLiveEventMutation,
} from '@fis/data-graphql';
import { EventCard } from '../../events/ui-event-card/ui-event-card';
import { formatAcronyms, formatDate } from '@fis/ui-format';
import { formatEventDate } from '../../util-format-event-date';
import RecommendedEvents from './recommended-events';
import {
  CardGridLoading,
  CardGridSection,
  CardGridSectionHeader,
} from '../../ui-card-grid';
import { UiCardRow } from '../ui-card-row';
import { RecommendedSectionContainer } from '../ui-bookmark-section-container';

export const BookmarkedEvents = () => {
  const { data, refetch, loading } = useGetSavedLiveEventsQuery({
    fetchPolicy: 'cache-first',
  });

  const [toggleSaveLiveEventMutation] = useToggleSaveLiveEventMutation();

  const toggleSaveLiveEvent = (uuid: string) => {
    toggleSaveLiveEventMutation({
      variables: {
        input: {
          liveEventUuid: uuid,
        },
      },
    });
  };

  if (loading) {
    return (
      <CardGridSection>
        <CardGridLoading />
      </CardGridSection>
    );
  } else if (data?.student?.savedLiveEvents.length === 0) {
    return <RecommendedEvents />;
  }

  const handleRefetch = () => {
    refetch();
  };

  const events = data?.student?.savedLiveEvents;

  const formatDisciplines = (programDisciplines) => {
    let disciplines;
    if (programDisciplines.length !== 0) {
      disciplines = formatAcronyms(
        programDisciplines.map((discipline) => discipline)
      ).join(', ');
    } else {
      return null;
    }
    return disciplines;
  };

  const handleDate = (date) => {
    return date ? formatDate(date) : null;
  };

  return (
    <RecommendedSectionContainer>
      <CardGridSectionHeader
        title={'Bookmarked Events'}
        link={'/events?recommended=true'}
      />
      <UiCardRow>
        {events
          ? events.map((event) => {
              return (
                <EventCard
                  verticalOrientation
                  key={event.uuid}
                  dataTestId={'EventCard.Root'}
                  eventTitle={event.title}
                  eventDate={handleDate(event.scheduledDate)}
                  eventTime={formatEventDate(
                    event.scheduledDate,
                    event.scheduledDurationSeconds
                  )}
                  eventSpeaker={event.speaker}
                  programDisciplines={formatDisciplines(
                    event.programDisciplines
                  )}
                  eventUrl={`/events/${event.slug}`}
                  remoteMeetingLink={event.remoteMeetingLink}
                  inPersonLocation={event.inPersonLocation}
                  imageUrl={event.mainImageUrl}
                  eventType={event.liveEventTypes}
                  bookmarkBtnClick={(e) => {
                    e.preventDefault();
                    toggleSaveLiveEvent(event.uuid);
                    handleRefetch();
                  }}
                  isBookmarked={event.saved}
                />
              );
            })
          : null}
      </UiCardRow>
    </RecommendedSectionContainer>
  );
};
