import { ContentFormatEnum } from '@fis/data-graphql';
import { BookmarkButton } from '@fis/ui-buttons';
import { Badge, BadgeRow } from '@fis/ui-badge';
import { Heading } from '@fis/ui-heading';
import { styled } from '@fis/util-theme';
import {
  Book,
  Eye,
  GlobeSimple,
  Headphones,
  MonitorPlay,
  SquaresFour,
  TextAlignLeft,
} from 'phosphor-react';

const formatIcon = (format: ContentFormatEnum) => {
  switch (format) {
    case ContentFormatEnum.Article:
      return <TextAlignLeft size={16} />;
    case ContentFormatEnum.Blog:
      return <GlobeSimple size={16} />;
    case ContentFormatEnum.Other:
      return <SquaresFour size={16} />;
    case ContentFormatEnum.Podcast:
      return <Headphones size={16} />;
    case ContentFormatEnum.Video:
      return <MonitorPlay size={16} />;
    default:
      return <SquaresFour size={16} />;
  }
};

const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '20.25rem',
  cursor: 'pointer',
  transitionDuration: '150ms',
  padding: '$2',
  borderRadius: '$2',
  color: 'white',
  textDecoration: 'none',
  gap: '$3',

  '&:focus': {
    outline: 'none',
    boxShadow: '$focusRing',
  },

  '&:hover': {
    background: '$tintDark8',
  },

  '.lightMode &': {
    color: '$gray700',

    '&:hover': {
      background: '$tintLight8',
    },
  },
});

const ImageContainer = styled('div', {
  background:
    'radial-gradient(107.24% 228.42% at 100% 100%, #1E2330 0%, #31394F 100%)',
  backgroundSize: 'cover',
  borderRadius: '$1',
  width: '100%',
  height: '12.5rem',
  overflow: 'hidden',
  marginBottom: '$2',
  position: 'relative',
});

const BadgeContainer = styled(BadgeRow, {
  zIndex: 10,
  position: 'absolute',
  bottom: 0,
  padding: '$3',
  gap: '$3',
});

const ContentImage = styled('div', {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
});

const CardTitleRow = styled('div', {
  display: 'flex',
  gap: '$3',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
});

const DisciplineRow = styled('div', {
  display: 'flex',
  gap: '$3',
  alignItems: 'center',

  '.lightMode &': {
    color: '$gray700',

    '& svg': {
      color: '$gray500',
    },
  },
});

const ContentLink = styled('a', {
  color: '$blue400',
  fontWeight: '$semibold',
  fontSize: '$20',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipses',
  overflow: 'hidden',
  textDecoration: 'underline',
  transitionDuration: '150ms',

  '&:hover': {
    color: '$blue300',
  },

  '.lightMode &': {
    color: '$blue700',

    '&:hover': {
      color: '$blue500',
    },
  },
});

// ———————————————————————————————————————————————————————————————————————————————————————————
// USAGE NOTES
//
// **This component needs a preventDefault() on the click event function that is passed into
// the bookmarkBtnClick attribute in order not to trigger the CardContainer's click event**
//
// ** When passing in the content URL, link to the Content Details page
//
// <StyledResourceCard
//     contentTitle="Test Content Title Which Is Very Long"
//     programDiscipline="SE"
//     contentUrl={contentDetailsPageUrl}
//     imageUrl={contentImageUrl}
//     bookmarkBtnClick={(e) => toggleSaveLiveEvent(e)}
//     isBookmarked={false}
// />
// ———————————————————————————————————————————————————————————————————————————————————————————

type StyledResourceCardProps = {
  contentTitle?: string;
  programDisciplines?: string;
  programPhases?: { name: string }[];
  contentUrl: string;
  contentSlug: string;
  contentFormat?: ContentFormatEnum;
  imageUrl: string;
  bookmarkBtnClick;
  handleEmbedUrlClick?: () => void;
  handleContentUrlClick?: () => void;
  isBookmarked: boolean;
  viewed: boolean;
  testId?: string;
};

export const StyledResourceCard = (props: StyledResourceCardProps) => {
  const {
    contentTitle,
    contentFormat,
    programDisciplines,
    contentUrl,
    viewed,
    contentSlug,
    imageUrl,
    bookmarkBtnClick,
    handleEmbedUrlClick,
    handleContentUrlClick,
    isBookmarked,
    testId,
  } = props;

  const domain = new URL(contentUrl);

  const formatContentType = (contentEnum) =>
    contentEnum.charAt(0).toUpperCase() + contentEnum.slice(1).toLowerCase();

  return (
    <CardContainer
      data-testid={testId ? testId : 'ResourceContent.Root'}
      aria-label="Resource Card"
      as="a"
      href={`/resource-library/${contentSlug}`}
      target="_blank"
      onClick={handleEmbedUrlClick}
    >
      <ImageContainer>
        <ContentImage css={{ backgroundImage: `url(${imageUrl})` }} />
        <BadgeContainer>
          {contentFormat ? (
            <Badge hasIcon="left" size="sm" type="content">
              {formatIcon(contentFormat)} {formatContentType(contentFormat)}
            </Badge>
          ) : null}
          {viewed ? (
            <Badge hasIcon="left" size="sm" type="viewed">
              <Eye size={16} weight="fill" />
              Viewed
            </Badge>
          ) : null}
        </BadgeContainer>
      </ImageContainer>
      <CardTitleRow>
        <Heading styling="med">{contentTitle}</Heading>
        <BookmarkButton
          data-testid="BookmarkBtn"
          onClick={bookmarkBtnClick} // requires e.preventDefault() to prevent clicks from reaching the parent card container
          isBookmarked={isBookmarked}
        />
      </CardTitleRow>
      {!programDisciplines ? null : (
        <DisciplineRow>
          <Book size={16} weight="regular" />
          <Heading styling="sm">{programDisciplines}</Heading>
        </DisciplineRow>
      )}
      <ContentLink
        onClick={handleContentUrlClick}
        href={contentUrl}
        target="_blank"
      >
        {domain.hostname}
      </ContentLink>
    </CardContainer>
  );
};
