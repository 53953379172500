import { useFeatureContinueLearningQuery } from '@fis/data-graphql';
import { FisLoadingIndicator } from '@fis/ui-loading-indicator';

import { ProgressPropTypes } from '../types';
import { ProgressCard } from './ui-progress-card';
import { formatCourseLinks } from '../../../util-format-course-links';

export const FeatureAlumniProgress = (props: ProgressPropTypes) => {
  const { data, error, loading } = useFeatureContinueLearningQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !error) {
    return <FisLoadingIndicator />;
  }
  const links = formatCourseLinks(data?.student.courses.edges) || [];

  return <ProgressCard {...props} links={links} />;
};
