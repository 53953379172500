import { styled } from '@fis/util-theme';
import { Heading } from '@fis/ui-heading';
import { IconButton } from '@fis/ui-buttons';

import { ArrowRight } from 'phosphor-react';

const CardHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '$3',
});

type ProgressCardHeaderPropTypes = {
  headerText: string | null;
  hideButton?: boolean | null;
};

export const ProgressCardHeader = (props: ProgressCardHeaderPropTypes) => {
  const { headerText, hideButton } = props;

  return (
    <CardHeader>
      <Heading styling="xxl">{headerText}</Heading>
      {hideButton ? null : (
        <IconButton buttonText={'View All Courses'} href={'/courses'} secondary>
          <ArrowRight size={16} />
        </IconButton>
      )}
    </CardHeader>
  );
};
