import { Button } from '@fis/ui-buttons';
import { Heading } from '@fis/ui-heading';
import { portalEndpoint } from '@fis/util-environment';
import { styled } from '@stitches/react';
import { ArrowRight } from 'phosphor-react';

const PreworkCardsContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '67.5rem',
  margin: '0 auto',
  gap: '$7',
});

const CourseCard = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: 'calc(50% - $3)',
  backgroundColor: '$gray800',
  borderRadius: '$2',
  color: '#FFF',
  gap: '$5',
  padding: '$3',
  textDecoration: 'none',

  '.lightMode &': {
    background: '$gray100',
  },

  variants: {
    single: {
      true: {
        maxWidth: '100%',
      },
    },
  },
});

const CourseImage = styled('div', {
  display: 'flex',
  width: '96px',
  height: '96px',
  backgroundSize: 'cover',
  overflow: 'hidden',
  backgroundPosition: 'center',
  borderRadius: '$1',
});

const CourseDetails = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$5',
  alignItems: 'center',
  padding: '0px $8 0px 0px',
  flexGrow: '1',
});

const portalUrl = portalEndpoint();

const preworkCourses = [
  {
    courseLink: `${portalUrl}/prep-enroll/cybersecurity-engineering`,
    disciplines: 'CSE',
    image: 'https://images.flatironschool.com/cyber-security.png',
    title: 'Cybersecurity Prep',
  },
  {
    courseLink: `${portalUrl}/prep-enroll/product-design`,
    disciplines: 'PD',
    image: 'https://images.flatironschool.com/product-design.png',
    title: 'Product Design Prep',
  },
  {
    courseLink: `${portalUrl}/prep-enroll/software-engineering`,
    disciplines: 'SE',
    image: 'https://images.flatironschool.com/software-engineering.png',
    title: 'Software Engineering Prep',
  },
  {
    courseLink: `${portalUrl}/prep-enroll/data-science`,
    disciplines: 'DS',
    image: 'https://images.flatironschool.com/data-science.png',
    title: 'Data Science Prep',
  },
];

export const UiProspectiveCourses = ({
  showSingleCard,
}: {
  showSingleCard?: string;
}) => {
  const filteredCourses = showSingleCard
    ? preworkCourses.filter((course) => course.disciplines === showSingleCard)
    : preworkCourses;
  return (
    <PreworkCardsContainer>
      {filteredCourses.map((course) => (
        <CourseCard single={!!showSingleCard} key={course.courseLink}>
          <CourseImage css={{ backgroundImage: `url(${course.image})` }} />
          <CourseDetails>
            <Heading styling="lg">{course.title}</Heading>
            <Button
              href={course.courseLink}
              rel="noopener noreferrer"
              target="_blank"
              as="a"
              secondary
              size="medium"
              hasIcon="right"
            >
              Start <ArrowRight weight="bold" />
            </Button>
          </CourseDetails>
        </CourseCard>
      ))}
    </PreworkCardsContainer>
  );
};

export default UiProspectiveCourses;
