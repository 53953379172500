import { Cohort } from '@fis/data-graphql';
import { styled } from '@fis/util-theme';
import { Badge, BadgeRow } from '@fis/ui-badge';
import { formatDate, formatTimeLeft } from '@fis/ui-format';

const Metadata = styled('span', {
  fontWeight: '$bold',

  variants: {
    emphasis: {
      true: {
        color: '$yellow400',
      },
    },
  },
});

type ProgressSubheaderPropTypes = {
  programStatus: string | null;
  graduatedAt?: Date;
  cohort?: Cohort | null;
};

export const ProgressSubheader = (props: ProgressSubheaderPropTypes) => {
  const { programStatus, cohort, graduatedAt } = props;

  switch (programStatus) {
    case 'prospective':
      return null;
    case 'committed':
      return (
        <BadgeRow data-testid="progress-subheader">
          {cohort?.courseOffering?.name ? (
            <Badge>{cohort.courseOffering.name}</Badge>
          ) : null}
          {cohort?.startDate && cohort?.endDate ? (
            <Badge>
              <span>Cohort:</span>
              <Metadata>
                {`${formatDate(cohort?.startDate)} - ${formatDate(
                  cohort?.endDate
                )}`}
              </Metadata>
            </Badge>
          ) : null}
        </BadgeRow>
      );
    case 'active':
      return (
        <BadgeRow data-testid="progress-subheader">
          {cohort?.courseOffering?.name ? (
            <Badge>{cohort.courseOffering.name}</Badge>
          ) : null}
          {cohort?.endDate ? (
            <Badge>
              <span>Cohort end:</span>
              <Metadata>{`${formatDate(cohort?.endDate)} •`}</Metadata>
              <Metadata emphasis>{`${
                formatTimeLeft(cohort?.endDate) || '0 weeks'
              } to go!`}</Metadata>
            </Badge>
          ) : null}
        </BadgeRow>
      );
    case 'alumni':
      return (
        <BadgeRow data-testid="progress-subheader">
          {cohort?.courseOffering?.name ? (
            <Badge>{`${cohort.courseOffering.name} Alumnus`}</Badge>
          ) : (
            <Badge>Alumnus</Badge>
          )}
          {graduatedAt ? (
            <Badge>
              <span>Graduated:</span>
              <Metadata>{formatDate(graduatedAt)}</Metadata>
            </Badge>
          ) : null}
        </BadgeRow>
      );
    default:
      return null;
  }
};
