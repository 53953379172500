import { toast } from 'react-toastify';
import {
  useGetAdmissionDateQuery,
  useGetHeaderDataQuery,
} from '@fis/data-graphql';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// init TimeAgo with locale; provides "in 4 days", "in 3 hours", etc
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

export function AdmissionInterviewNotification() {
  const { data: student } = useGetHeaderDataQuery({
    fetchPolicy: 'cache-first',
  });
  const { data } = useGetAdmissionDateQuery({
    fetchPolicy: 'cache-first',
  });

  if (!student || !data) return null;

  const admissionsInterviewDate = data.student.admissionsInterviewDate
    ? new Date(`${data.student.admissionsInterviewDate}`)
    : null;
  if (admissionsInterviewDate) {
    const key = `admission-interview-date-${admissionsInterviewDate}`;
    const show =
      student.student.programStatus.toLocaleLowerCase() === 'prospective' &&
      !window.localStorage.getItem(key) &&
      admissionsInterviewDate > new Date(); // show if not shown before AND admissions date is in the future
    if (show) {
      toast.success(
        () => (
          <time dateTime={data.student.admissionsInterviewDate}>
            You have an admission interview scheduled
            {' ' + timeAgo.format(admissionsInterviewDate, 'twitter-now')}!
          </time>
        ),
        {
          autoClose: false,
          closeOnClick: true,
        }
      );
      window.localStorage.setItem(key, 'true');
    }
  }

  return null;
}
