import {
  useGetAvailableLiveEventsQuery,
  useToggleSaveLiveEventMutation,
} from '@fis/data-graphql';
import { EventCard } from '../../events/ui-event-card/ui-event-card';
import { formatAcronyms, formatDate } from '@fis/ui-format';
import { formatEventDate } from '../../util-format-event-date';
import {
  CardGrid,
  CardGridLoading,
  CardGridSection,
  CardGridSectionHeader,
} from '../../ui-card-grid';

export const RecommendedEvents = () => {
  const { data, loading, error, refetch } = useGetAvailableLiveEventsQuery({
    variables: {
      limit: 3,
      filters: {
        liveEventTypeUuids: ['a4ea517d-8ae7-4015-8588-fed7929bad6c'],
      },
    },
  });

  const [toggleSaveLiveEventMutation] = useToggleSaveLiveEventMutation();

  const toggleSaveLiveEvent = (uuid: string) => {
    toggleSaveLiveEventMutation({
      variables: {
        input: {
          liveEventUuid: uuid,
        },
      },
    });
  };

  if (loading) {
    return (
      <CardGridSection>
        <CardGridLoading />
      </CardGridSection>
    );
  } else if (
    !data?.student?.availableLiveEvents?.liveEvents ||
    data?.student?.availableLiveEvents?.liveEvents.length < 1
  ) {
    return null;
  }

  if (error) return null;

  const handleRefetch = () => {
    refetch();
  };

  const formatDisciplines = (programDisciplines) => {
    let disciplines;
    if (programDisciplines.length !== 0) {
      disciplines = formatAcronyms(
        programDisciplines.map((discipline) => discipline)
      ).join(', ');
    } else {
      return null;
    }
    return disciplines;
  };

  const handleDate = (date) => {
    return date ? formatDate(date) : null;
  };

  return (
    <CardGridSection>
      <CardGridSectionHeader
        title={'Events for You'}
        link={'/events?recommended=true'}
      />
      <CardGrid>
        {data?.student?.availableLiveEvents?.liveEvents
          ? data?.student?.availableLiveEvents?.liveEvents.map((event) => {
              return (
                <EventCard
                  verticalOrientation
                  key={event.uuid}
                  dataTestId={'EventCard.Root'}
                  eventTitle={event.title}
                  eventDate={handleDate(event.scheduledDate)}
                  eventTime={formatEventDate(
                    event.scheduledDate,
                    event.scheduledDurationSeconds
                  )}
                  eventSpeaker={event.speaker}
                  programDisciplines={formatDisciplines(
                    event.programDisciplines
                  )}
                  eventUrl={`/events/${event.slug}`}
                  remoteMeetingLink={event.remoteMeetingLink}
                  inPersonLocation={event.inPersonLocation}
                  imageUrl={event.mainImageUrl}
                  eventType={event.liveEventTypes}
                  bookmarkBtnClick={(e) => {
                    e.preventDefault();
                    toggleSaveLiveEvent(event.uuid);
                    handleRefetch();
                  }}
                  isBookmarked={event.saved}
                />
              );
            })
          : null}
      </CardGrid>
    </CardGridSection>
  );
};

export default RecommendedEvents;
