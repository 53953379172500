import { styled } from '@stitches/react';
import { Button, IconButton } from '@fis/ui-buttons';
import { ArrowRight } from 'phosphor-react';
import { Heading } from '@fis/ui-heading';

export const CardGridSection = styled('div', {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  margin: '0 auto',
  width: '100%',
  maxWidth: '67.5rem',
});

export const CardGrid = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.875rem',
});

const CardGridLoadingPlaceholder = styled('div', {
  height: '350px',
});

export const CardGridLoading = () => (
  <CardGrid>
    <CardGridLoadingPlaceholder />
  </CardGrid>
);

const CardGridHeading = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '3.375rem',
  padding: '3rem 0 1.5rem',

  variants: {
    type: {
      full: {
        justifyContent: 'space-between',
      },
    },
  },
});

type CardGridSection = {
  title: string;
  link?: string;
  buttonText?: string;
  handleShowMore?: () => void;
  type?: 'full' | undefined;
};

export const CardGridSectionHeader = ({
  title,
  link,
  handleShowMore,
  buttonText,
  type,
}: CardGridSection) => {
  return (
    <CardGridHeading type={type}>
      <Heading styling="lg">{title}</Heading>
      {link ? (
        <IconButton
          buttonText={buttonText ? buttonText : 'View all'}
          href={link}
          secondary
        >
          <ArrowRight size={16} />
        </IconButton>
      ) : (
        <Button secondary onClick={handleShowMore}>
          View More
        </Button>
      )}
    </CardGridHeading>
  );
};
