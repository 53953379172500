import { styled } from '@fis/util-theme';
import { ResponsiveFlexContainer } from '@fis/ui-responsive-flex-container';
import { Book, LinkSimple, MapPinLine, User } from 'phosphor-react';
import { UiLink } from '@fis/ui-link';

const LocationRow = styled('div', {
  display: 'flex',
  gap: '$3',
});

const Disciplines = styled('span', {
  color: '$white500',
  fontWeight: '$bold',

  '.lightMode &': {
    color: '$gray700',
  },
});

const SpeakerRow = styled('div', {
  display: 'flex',
  gap: '$4',
});

const LinkRow = styled('div', {
  display: 'flex',
  gap: '$3',
  width: '100%',
  minWidth: '0',
});

export const UiEventCardBody = (props: {
  remoteMeetingLink;
  inPersonLocation;
  programDisciplines;
  programPhases;
  eventSpeaker;
  eventType;
  verticalOrientation;
}) => {
  const {
    remoteMeetingLink,
    inPersonLocation,
    programDisciplines,
    programPhases,
    eventSpeaker,
    eventType,
    verticalOrientation,
  } = props;

  return (
    <ResponsiveFlexContainer>
      <ResponsiveFlexContainer flexColumn>
        {remoteMeetingLink && (
          <LinkRow>
            <LinkSimple size={16} weight="bold" />
            <UiLink
              linkText={remoteMeetingLink}
              openInNewTab
              href={remoteMeetingLink}
              stopPropagation
            />
          </LinkRow>
        )}

        {inPersonLocation && (
          <LocationRow>
            <MapPinLine size={16} weight="bold" />
            {inPersonLocation}
          </LocationRow>
        )}

        <ResponsiveFlexContainer>
          <Book size={16} weight="bold" />
          <Disciplines>{programDisciplines}</Disciplines>
          {programPhases}
          {!eventSpeaker && verticalOrientation ? (
            <span> • {eventType}</span>
          ) : null}
        </ResponsiveFlexContainer>
        {eventSpeaker && (
          <SpeakerRow>
            <User size={16} weight="bold" />
            {eventSpeaker} • {eventType}
          </SpeakerRow>
        )}
      </ResponsiveFlexContainer>
    </ResponsiveFlexContainer>
  );
};
