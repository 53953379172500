import { styled } from '@stitches/react';
import * as Section from '@fis/ui-section';
import BookmarkedResources from './bookmarked-resources';
import BookmarkedEvents from './bookmarked-events';

const HomepageResourcesAndEvents = styled(Section.Root, {
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
});

const FeatureHomepageResourcesAndEvents = () => {
  return (
    <HomepageResourcesAndEvents data-testid="FeatureHomepageResourcesAndEvents">
      <BookmarkedResources />
      <BookmarkedEvents />
    </HomepageResourcesAndEvents>
  );
};

export default FeatureHomepageResourcesAndEvents;
