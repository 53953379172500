import React from 'react';
import { useFeatureRecommendedContentQuery } from '@fis/data-graphql';
import { UiRecommendedResourceHomepageCta } from '../recommended-resource-homepage-cta/ui-recommended-resource-homepage-cta';

export const FeatureRecommendedResourceHomepageCta = () => {
  const { data, error } = useFeatureRecommendedContentQuery();
  const loading = !error && !data;

  if (error || !data || data.student.recommendations.length === 0) {
    return null;
  }

  return data.student.recommendations.length > 0 ? (
    <UiRecommendedResourceHomepageCta
      resourcesTotal={data.student.recommendations.length}
    />
  ) : null;
};
