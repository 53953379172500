import { styled } from '@fis/util-theme';

export const UiCardRow = styled('div', {
  display: 'flex',
  gap: '3.375rem',
  flexWrap: 'wrap',

  '@md': {
    justifyContent: 'space-around',
    gap: '$9',
  },
});
