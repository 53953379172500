import { formatDate } from '@fis/ui-format';

export const formatEventDate = (
  scheduledDate: string,
  scheduledDurationSeconds: number
) => {
  const now = new Date();
  const startDate = new Date(scheduledDate);
  const endDate = new Date();

  endDate.setTime(startDate.getTime() + scheduledDurationSeconds * 1000);

  let formattedStartDate = null;
  let formattedEndDate = null;

  const check = {
    now: {
      day: now.getDate(),
      month: now.getMonth(),
    },
    start: {
      day: startDate.getDate(),
      month: startDate.getMonth(),
    },
    end: {
      day: endDate.getDate(),
      month: endDate.getMonth(),
    },
  };

  if (
    check.start.month === check.now.month &&
    check.start.day === check.now.day
  ) {
    formattedStartDate = `${formatDate(startDate, {
      year: null,
      month: null,
      day: null,
      hour: 'numeric',
      minute: '2-digit',
    })}`;
  } else {
    formattedStartDate = formatDate(startDate, {
      year: null,
      month: null,
      day: null,
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  if (
    check.start.month === check.end.month &&
    check.start.day === check.end.day
  ) {
    formattedEndDate = `${formatDate(endDate, {
      year: null,
      month: null,
      day: null,
      hour: 'numeric',
      minute: '2-digit',
    })}`;
  } else {
    formattedEndDate = formatDate(endDate, {
      year: null,
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  return `${formattedStartDate} - ${formattedEndDate}`.replaceAll(',', '');
};
