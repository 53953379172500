import * as Section from '@fis/ui-section';
import {
  FacebookLogo,
  GithubLogo,
  InstagramLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'phosphor-react';
import { styled } from '@stitches/react';

const Card = styled('div', {
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  maxWidth: '42rem',
  margin: '0 auto',
  flexDirection: 'column',
  gap: '3rem',
  justifyContent: 'center',
  padding: '2rem',
  textAlign: 'center',
});

const SocialContainer = styled('div', {
  display: 'flex',
  gridTemplateAreas: 'Socials',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  width: '100%',
});

const SocialLink = styled('a', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$round',
  backgroundColor: 'transparent',
  color: '$lightblue300',
  cursor: 'pointer',
  fontSize: '2rem',
  transitionDuration: '150ms',
  height: '2.5rem',
  width: '2.5rem',

  '.lightMode &': {
    color: '$lightblue600',
  },

  '&:focus': {
    outline: 'none',
    boxShadow: '$focusRing',
    background: '$tintDark8',

    '.lightMode &': {
      background: '$tintLight16',
    },
  },

  '&:hover': {
    backgroundColor: '$tintDark16',
    color: '$lightblue100',

    '.lightMode &': {
      backgroundColor: '$tintLight16',
      color: '$lightblue700',
    },
  },
});

export const SocialLinks = () => {
  return (
    <Section.Root data-testid="FeatureProspectiveTitle">
      <Section.Content>
        <Card>
          <SocialContainer data-cy="social">
            <SocialLink
              href="https://www.facebook.com/FlatironSchool/"
              title="Facebook"
            >
              <FacebookLogo />
            </SocialLink>

            <SocialLink
              href="https://twitter.com/flatironschool"
              title="Twitter"
            >
              <TwitterLogo />
            </SocialLink>

            <SocialLink
              href="https://instagram.com/flatironschool"
              title="Instagram"
            >
              <InstagramLogo />
            </SocialLink>

            <SocialLink
              href="https://github.com/flatiron-school"
              title="GitHub"
            >
              <GithubLogo />
            </SocialLink>

            <SocialLink
              href="https://www.youtube.com/c/FlatironSchoolOfficial/"
              title="Youtube"
            >
              <YoutubeLogo />
            </SocialLink>
          </SocialContainer>
        </Card>
      </Section.Content>
    </Section.Root>
  );
};
