import React from 'react';
import { FeatureProspectiveProgress } from './components/feature-prospective-progress';
import { FeatureCommittedProgress } from './components/feature-committed-progress';
import { FeatureActiveProgress } from './components/feature-active-progress';
import { FeatureAlumniProgress } from './components/feature-alumni-progress';
import { Student, Cohort } from '@fis/data-graphql';

export const FeatureProgressCard = ({
  student,
}: {
  student: Pick<
    Student,
    'firstName' | 'programStatus' | 'uuid' | 'startedPrework' | 'graduated'
  > & {
    cohort?: Cohort;
  };
}) => {
  switch (student.programStatus) {
    case 'prospective':
      return (
        <FeatureProspectiveProgress
          userFirstName={student?.firstName || null}
          programStatus={student?.programStatus || null}
          cohort={student?.cohort}
          startedPrework={student?.startedPrework}
        />
      );
    case 'committed':
      return (
        <FeatureCommittedProgress
          userFirstName={student?.firstName || null}
          programStatus={student?.programStatus || null}
          cohort={student?.cohort}
          startedPrework={student?.startedPrework}
        />
      );
    case 'active':
      return (
        <FeatureActiveProgress
          userFirstName={student?.firstName || null}
          programStatus={student?.programStatus || null}
          cohort={student?.cohort}
        />
      );
    case 'alumni':
      return (
        <FeatureAlumniProgress
          userFirstName={student?.firstName || null}
          programStatus={student?.programStatus || null}
          graduatedAt={student?.graduated?.graduatedAt}
          cohort={student?.cohort}
        />
      );
    default:
      return null;
  }
};
