export const formatCourseLinks = (canvasEdges) => {
  // Courses are a connection type so we need to map over the edges and extract each node as well as add a source property to match the links from the continueLearning query
  const canvasCourses = canvasEdges.map((e) => ({
    ...e.node,
    source: 'canvas',
  }));

  const sortedLinks = canvasCourses.sort((a, b) => {
    const aDate = a.lastAccessDate === null ? '' : a.lastAccessDate;
    const bDate = b.lastAccessDate === null ? '' : b.lastAccessDate;
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });
  return sortedLinks;
};
