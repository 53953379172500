import { Heading } from '@fis/ui-heading';
import { styled } from '@fis/util-theme';
import { UiProgressBar } from './ui-progress-bar';

const CardContainer = styled('div', {
  backgroundColor: '$tintDark8',
  borderRadius: '$3',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$3',
  padding: '$7',
  textDecoration: 'none',
  transitionDuration: '150ms',

  '&:focus': {
    outline: 'none',
    boxShadow: '$focusRing',
  },

  // sets focus style for webkit browsers
  '@supports selector(:focus-visible)': {
    // negates :focus rule for browsers that do not support :focus-visible selector
    '&:focus': {
      boxShadow: 'none',
      outline: 'none',
    },

    '&:focus-visible': {
      boxShadow: '$focusRing',
      outline: 'none',
    },
  },

  '&:hover': {
    borderColor: '$lightblue400',
    boxShadow: '$blueGlow',
    cursor: 'pointer',
  },

  '.lightMode &': {
    background: '$gray100',

    '&:hover': {
      background: 'white',
      boxShadow: '$1',
    },
  },
});

const ContinueProgressSource = styled('p', {
  color: '$lightblue400',
  fontSize: '$20',
  fontWeight: 'bold',
  textTransform: 'capitalize',

  '.lightMode &': {
    color: '$lightblue700',
  },
});

type ContinueProgressCardProps = {
  courseUrl: string;
  name: string;
  source: string;
  completedTasks?: number;
  totalTasks?: number;
};

export const ContinueProgressCard = (props: ContinueProgressCardProps) => {
  const { courseUrl, name, source, completedTasks, totalTasks } = props;

  return (
    <CardContainer as="a" href={courseUrl} data-testid="continue-progress-card">
      <ContinueProgressSource>{source}</ContinueProgressSource>
      <Heading styling="med">{name}</Heading>
      {completedTasks && totalTasks ? (
        <UiProgressBar value={completedTasks} max={totalTasks} />
      ) : null}
    </CardContainer>
  );
};
