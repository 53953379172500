import { Cohort } from '@fis/data-graphql';
import { styled } from '@fis/util-theme';

import { LinkType } from '../types';

import { ContinueProgressCard } from './ui-continue-progress-card';
import { ProgressCardHeader } from './ui-progress-card-header';
import { ProgressSubheader } from './ui-progress-subheader';

const Card = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '67.5rem',
  margin: '0 auto 3rem',
  gap: '$5',
});

type ProgressCardPropTypes = {
  userFirstName: string | null;
  programStatus: string | null;
  graduatedAt?: Date;
  cohort?: Cohort | null;
  links?: LinkType[] | null;
};

export const ProgressCard = (props: ProgressCardPropTypes) => {
  const { userFirstName, programStatus, cohort, links, graduatedAt } = props;

  const display_links = links.slice(0, 3);

  return (
    <Card>
      <ProgressCardHeader
        headerText={`Welcome${userFirstName ? ', ' + userFirstName : ''}!`}
        hideButton={links.length === 0}
      />
      <ProgressSubheader
        programStatus={programStatus}
        cohort={cohort}
        graduatedAt={graduatedAt}
      />
      {display_links.map((link) => (
        <ContinueProgressCard
          courseUrl={link?.courseUrl as string}
          key={`${link?.name}-${link?.courseUrl}`}
          source={link?.source as string}
          name={link?.name as string}
          completedTasks={link?.completedTasks as number}
          totalTasks={link?.totalTasks as number}
        />
      ))}
    </Card>
  );
};
