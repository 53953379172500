import { UiLink } from '@fis/ui-link';
import { styled } from '@fis/util-theme';

/*
 * These messages & CTAs match codes that are passed as query parameters from other services.
 * See https://docs.google.com/spreadsheets/d/1QpGjLFbPM6E4bzA_0gDEpaUUTG_fO6728YaBAxDBxkM/edit#gid=0
 */
const errorMessageMap = {
  1000: {
    msg: 'Please authorize the application in order to gain access to our Discord community.',
    ctaLink: 'https://portal.flatironschool.com/chat',
    ctaText: 'Try Again',
  },
  1001: {
    msg: 'You do not have access to this Discord community. Please contact support if that seems incorrect.',
    ctaLink: 'mailto:operations@flatironschool.com',
    ctaText: 'Contact Support',
  },
  1002: {
    msg: 'This Discord user has already been linked to another Portal account.',
  },
  1003: {
    msg: 'Your Portal account has already been linked to another Discord user.',
  },
  1004: {
    msg: 'We were unable to link your Discord account at the moment. Please try again later.',
  },
  1005: {
    msg: 'We were unable to link your Discord account at the moment. Please try again later.',
    ctaLink: 'https://portal.flatironschool.com/chat',
    ctaText: 'Try Again',
  },
  1006: {
    msg: 'We were unable to link your Discord account at the moment. Please try again later.',
    ctaLink: 'https://portal.flatironschool.com/chat',
    ctaText: 'Try Again',
  },
  1007: {
    msg: 'You have reached the server limit for your Discord account. Please contact support.',
    ctaLink: 'mailto:operations@flatironschool.com',
    ctaText: 'Contact Support',
  },
  1008: {
    msg: 'You do not have access to this Discord community. Please contact support if that seems incorrect.',
    ctaLink: 'mailto:operations@flatironschool.com',
    ctaText: 'Contact Support',
  },
  1009: {
    msg: 'We were unable to link your Discord account automatically. Please contact support.',
    ctaLink: 'mailto:operations@flatironschool.com',
    ctaText: 'Contact Support',
  },
  1100: {
    msg: 'Something went wrong. Please try again. Contact operations@flatironschool.com if the issue continues.',
    ctaLink: 'https://portal.flatironschool.com/chat',
    ctaText: 'Try Again',
  },
};

const MessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  p: '$2',
  fontSize: '$2',
  fontWeight: '$semibold',
  textAlign: 'center',
  width: '100%',
});

export const ToastErrorMessage = ({ code }: { code: string }) => {
  const error = errorMessageMap[code] || errorMessageMap[1100];
  return (
    <MessageContainer>
      <p>{error.msg}</p>
      {error.ctaText && error.ctaLink && (
        <UiLink linkText={error.ctaText} href={error.ctaLink} />
      )}
    </MessageContainer>
  );
};

export default ToastErrorMessage;
