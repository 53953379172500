import { useFeatureContinueLearningQuery } from '@fis/data-graphql';
import { FisLoadingIndicator } from '@fis/ui-loading-indicator';

import { ProgressPropTypes } from '../types';
import { ProgressCard } from './ui-progress-card';
import { UiHeroTitle } from '@fis/ui-hero-title';
import { UiProspectiveCourses } from '../../prospective-courses/ui-prospective-courses';
import { formatCourseLinks } from '../../../util-format-course-links';

const disciplineShortNameMap = {
  'Software Engineering': 'SE',
  'Cybersecurity Engineering': 'CSE',
  'Product Design': 'PD',
  'Data Science': 'DS',
};

export const FeatureCommittedProgress = (props: ProgressPropTypes) => {
  const { data, error } = useFeatureContinueLearningQuery({
    fetchPolicy: 'cache-and-network',
  });

  const loading = !error && !data;

  const canvasEdges = data?.student?.courses?.edges || [];
  const formattedLinks = formatCourseLinks(canvasEdges);
  const startedPrework = props.startedPrework;

  if (loading) {
    return <FisLoadingIndicator />;
  }

  if (!startedPrework) {
    return (
      <>
        <UiHeroTitle titleText="Start your prep work" />
        <UiProspectiveCourses
          showSingleCard={disciplineShortNameMap[props.cohort?.disciplineName]}
        />
      </>
    );
  }

  return <ProgressCard {...props} links={formattedLinks} />;
};
