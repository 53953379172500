import React from 'react';
import { styled } from '@fis/util-theme';
import { Button } from '@fis/ui-buttons';
import { Heading } from '@fis/ui-heading';
import { ArrowRight } from 'phosphor-react';
import Link from 'next/link';

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '$gray800',
  borderRadius: '$3',
  gap: '$9',
  padding: '$9',
  margin: '0 auto',
  width: '100%',
  maxWidth: '67.5rem',

  '.lightMode &': {
    color: '$gray700',
    background: '$gray100',
  },
});

const SubContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$9',
});

const NumberContainer = styled('div', {
  borderRadius: '$round',
  background: '$lightblue900',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '$13',
  height: '$13',

  '.lightMode &': {
    color: '$gray700',
    background: '$lightblue200',
  },
});

export const UiRecommendedResourceHomepageCta = (props: {
  resourcesTotal: number;
}) => {
  const { resourcesTotal } = props;
  return (
    <Container>
      <SubContainer>
        <NumberContainer>
          <Heading styling="lg">{resourcesTotal}</Heading>
        </NumberContainer>
        <Heading styling="lg">Recommended Resources</Heading>
      </SubContainer>
      <Link href={'/recommended-resources'}>
        <Button hasIcon="iconOnly" secondary>
          <ArrowRight size={20} />
        </Button>
      </Link>
    </Container>
  );
};
